import React from 'react'
import { useTranslation } from '../../i18n'
import {
  Categories,
  LinkToCategory,
  Wrapper,
  WineList,
  GroupTitle,
  WineGroupWrapper,
  WineName,
  WineType,
  WineWrapper,
  WineSubcategory,
  WineProducer
} from './css'

const Category = ({ category, index, isLast }) => (
  <LinkToCategory href={`#wineGroup_${index}`} isLast={isLast}>
    {category}
  </LinkToCategory>
)

const WineGroup = ({ category, subCategories, items, index }) => (
  <WineGroupWrapper id={`wineGroup_${index}`}>
    <GroupTitle>{category}</GroupTitle>
    {subCategories &&
      subCategories.map(({ name, color, items }) => (
        <div key={name}>
          <WineSubcategory color={color}>{name}</WineSubcategory>
          {items.map(wine => (
            <Wine key={wine.name} {...wine} />
          ))}
        </div>
      ))}
    {items && items.map(wine => <Wine key={wine.name} {...wine} />)}
  </WineGroupWrapper>
)

const Wine = ({ name, type, producer }) => (
  <WineWrapper>
    <WineName>{name}</WineName>
    <WineType>{type}</WineType>
    {producer && <WineProducer>{producer}</WineProducer>}
  </WineWrapper>
)

const WinePage = () => {
  const { t } = useTranslation()
  const wineList = t('wines')
  return (
    <Wrapper>
      <Categories>
        {wineList.map((wineGroup, index) => (
          <Category
            index={index}
            key={wineGroup.category}
            category={wineGroup.category}
            isLast={index === wineList.length - 1}
          />
        ))}
      </Categories>
      <WineList>
        {wineList.map((wineGroup, index) => (
          <WineGroup key={wineGroup.category} index={index} {...wineGroup} />
        ))}
      </WineList>
    </Wrapper>
  )
}

export default WinePage
