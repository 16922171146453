import styled from 'styled-components'
import { primaryFont } from '../../GlobalStyles'

export const Wrapper = styled.div`
  color: #1a1818;
  ${primaryFont};
`

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0;
`

export const LinkToCategory = styled.a`
  display: flex;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  color: #1a1818;
  text-transform: lowercase;
  ${props => (props.isLast ? 'margin-top: 20px' : '')};
`

export const WineList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`

export const WineGroupWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`

export const GroupTitle = styled.h2`
  ${primaryFont};
  position: relative;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: inline-block;
`

export const WineWrapper = styled.div`
  margin-bottom: 20px;
`

export const WineName = styled.h3`
  ${primaryFont};
  font-size: 22px;
  white-space: pre-line;
  margin: 0;
  text-transform: uppercase;
`
export const WineType = styled.h4`
  ${primaryFont};
  font-size: 17px;
  display: block;
  white-space: pre-line;
  margin: 0;
`

export const WineProducer = styled.h5`
  ${primaryFont};
  font-size: 17px;
  display: inline-block;
  white-space: pre-line;
  margin: 0;
`

export const WineSubcategory = styled.h3`
  ${primaryFont};
  margin: 0 0 10px;
  font-size: 22px;
  color: ${props => props.color};
`
